import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import Home from '../components/Home';
import Movies from '../components/Movies';
import Snacks from '../components/Snacks';
import Error from '../components/Error';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/movies">
        <Movies />
      </Route>
      <Route exact path="/snacks">
        <Snacks />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/error">
        <Error />
      </Route>
      <Route render={() => <Redirect to={{ pathname: '/error' }} />} />
    </Switch>
  )
}

export default Routes;
