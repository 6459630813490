import React from 'react';
import { useMsal } from "@azure/msal-react";
import { Link } from 'react-router-dom';

const Nav = () => {
  const { instance } = useMsal();
  const handleLogout = () => {
    instance.logout();
  }
  return (
    <nav className="Nav">
      <ul className="Nav__list">
        <li className="Nav__item">
          <Link to="/">Home</Link>
        </li>
        <li className="Nav__item">
          <Link to="/movies">Movies</Link>
        </li>
        <li className="Nav__item">
          <Link to="/snacks">Snacks</Link>
        </li>
        <li className="Nav__item Nav__item--last">
          <button onClick={handleLogout}>Logout</button>
        </li>
      </ul>
    </nav>
  )
}

export default Nav;
