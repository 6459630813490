import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';

import './index.css';
import App from './App';
import history from './routes/history';
import { msalInstance } from './config/msal';

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Router history={history}>
        <App />
      </Router>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

