import { AuthenticatedTemplate, useMsalAuthentication, UnauthenticatedTemplate } from '@azure/msal-react';
import './App.css';
import Routes from './routes/Routes';
import Nav from './components/Nav';

function App() {
  const { error } = useMsalAuthentication("redirect");

  if (error) {
    return (
      <UnauthenticatedTemplate>
        <p>Failed to login</p>
      </UnauthenticatedTemplate>
    )
  }
  return (
    <AuthenticatedTemplate>
      <div className="App">
        <Nav />
        <Routes />
      </div>
    </AuthenticatedTemplate>
  );
}

export default App;
