import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: window.__ENV__.REACT_APP_ADB2C_CLIENT_ID || process.env.REACT_APP_ADB2C_CLIENT_ID,
    authority: window.__ENV__.REACT_APP_ADB2C_AUTHORITY || process.env.REACT_APP_ADB2C_AUTHORITY,
    knownAuthorities: [window.__ENV__.REACT_APP_ADB2C_KNOWN_AUTHORITIES || process.env.REACT_APP_ADB2C_KNOWN_AUTHORITIES],
    redirectUri: window.__ENV__.REACT_APP_ADB2C_REDIRECT_URI || process.env.REACT_APP_ADB2C_REDIRECT_URI,
    postLogoutRedirectUri: window.__ENV__.REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI || process.env.REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

console.log(msalConfig);

const msalInstance = new PublicClientApplication(msalConfig);

export { msalInstance }
